import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { ExtendedButton, makeStyles } from "@placehires/react-component-library";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import React, { ChangeEvent, useState } from "react";
import IntroSection from "../components/public/IntroSection";
import PublicLayout from "../layout/PublicLayout";
import { handleInputChangeWithName } from "../utils/inputUtils";

const TEXT_FIELD_OPTIONS = {
  fullWidth: true,
  InputLabelProps: {
    shrink: true
  }
};

const IMAGE_QUERY = graphql`
  query {
    headerImage: file(relativePath: { eq: "contactUs.png" }) {
      ...fluidImage
    }
  }
`;

const ContactUs = () => {
  const { classes } = useStyles();
  const userType = ((useLocation().state as any)?.userType || "") as "applicant" | "recruiter";
  const imagesQueryResult = useStaticQuery(IMAGE_QUERY);
  const headerImage = imagesQueryResult.headerImage.childImageSharp.gatsbyImageData;
  const [contactInfo, setContactInfo] = useState({ userType });

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    handleInputChangeWithName(e, contactInfo, setContactInfo);
  };

  return (
    <PublicLayout seoTitle="Contact Us">
      <IntroSection image={headerImage} title="Contact Us" imageOverlay />
      <Container>
        <div className={classes.bodyWrapper}>
          <Typography variant="h3" component="h2" color="primary">
            Your Information
          </Typography>
          <form>
            <FormControl component="fieldset" className={classes.userTypeForm}>
              <FormLabel component="legend">Are you an applicant or a restaurant?</FormLabel>
              <RadioGroup
                value={contactInfo.userType}
                row
                name="userType"
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="applicant"
                  control={<Radio color="primary" />}
                  label="Applicant"
                />
                <FormControlLabel
                  value="recruiter"
                  control={<Radio color="primary" />}
                  label="Recruiter"
                />
              </RadioGroup>
            </FormControl>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={handleInputChange}
                  autoComplete="given-name"
                  name="firstName"
                  required
                  label="First Name"
                  placeholder="Enter your first name"
                  {...TEXT_FIELD_OPTIONS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={handleInputChange}
                  required
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  placeholder="Enter your last name"
                  {...TEXT_FIELD_OPTIONS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={handleInputChange}
                  required
                  label="Email"
                  name="email"
                  autoComplete="email"
                  placeholder="Enter your email"
                  {...TEXT_FIELD_OPTIONS}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={handleInputChange}
                  autoComplete="tel-national"
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Enter your phone number"
                  {...TEXT_FIELD_OPTIONS}
                />
              </Grid>
              {contactInfo.userType === "recruiter" && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h3" color="primary">
                      Company Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={handleInputChange}
                      required
                      label="Company Name"
                      name="companyName"
                      placeholder="Enter company name"
                      {...TEXT_FIELD_OPTIONS}
                    />
                  </Grid>
                  {/*// TODO: grid split*/}
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Questions you might have about:</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name="generalInquiries"
                              onChange={handleInputChange}
                            />
                          }
                          label="General Inquiries"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name="improvements"
                              onChange={handleInputChange}
                            />
                          }
                          label="Improvements"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="technicalSupport"
                              onChange={handleInputChange}
                            />
                          }
                          label="Technical Support"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="requestDemo"
                              onChange={handleInputChange}
                            />
                          }
                          label="Request a Demo"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </>
              )}

              {contactInfo.userType && (
                <Grid item xs={12}>
                  <TextField
                    onChange={handleInputChange}
                    name="comments"
                    label="Comments"
                    multiline
                    rows={4}
                    variant="outlined"
                    className={classes.textArea}
                    {...TEXT_FIELD_OPTIONS}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedA} onChange={handleChange}
                          name="checkedA"
                          color="primary"
                        />
                      }
                      label="I would like to participate in the testing phase to improve PlaceHires."
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedB}
                          // onChange={handleChange}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="I would like to receive updates on PlaceHires."
                    />
                  </FormGroup>
                  <ExtendedButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={false}
                    size="large"
                    className={classes.submit}
                  >
                    Submit
                  </ExtendedButton>
                </Grid>
              )}
            </Grid>
          </form>
        </div>
      </Container>
    </PublicLayout>
  );
};

const useStyles = makeStyles()((theme) => ({
  bodyWrapper: {
    padding: theme.spacing(4, 0)
  },
  userTypeForm: {
    margin: theme.spacing(2, 0)
  },
  textArea: {
    marginBottom: theme.spacing(2)
  },
  submit: {
    marginTop: theme.spacing(2)
  }
}));

export default ContactUs;
